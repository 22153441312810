import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Button, Dimmer, Dropdown, Form, Grid, Loader} from 'semantic-ui-react';
import Date from '../../components/BaseComponents/Date';
import { useTranslation } from 'react-i18next';
import {getLookupRequest, valuesListSelector} from "../../ducks/lookup";
import Select from "../../components/BaseComponents/Select";
import { ALLOCATIONS_PLAN_ACTUAL_INDICATORS_REPORT_TYPE, DIRECTION_ALLOCATIONS_PLAN_ACTUAL_REPORT_TYPE, REFUSAL_REPORT_TYPE } from '../../constants/reportType';
import { exportProgressSelector, progressSelector } from '../../ducks/reports';
const ReportFilters = ({
    params,
    setParams, 
    onGenerateReport,
    onExportExcel,
    reportType,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const ordersList = useSelector(state => valuesListSelector(state, 'orders'));
    let [ordersSelectList, setOrdersSelectList] = useState([]);
    
    const carriersList = useSelector(state => valuesListSelector(state, 'transportCompanies'));
    const shippingNumberList = useSelector(state => valuesListSelector(state, 'shippingNumber'));
    
    const shippingCitiesList = useSelector(state => valuesListSelector(state, 'shippingCity'));
    const deliveryCitiesList = useSelector(state => valuesListSelector(state, 'deliveryCity'));
    
    const shippingRegionsList = useSelector(state => valuesListSelector(state, 'shippingRegion'));
    const deliveryRegionsList = useSelector(state => valuesListSelector(state, 'deliveryRegion'));
    
    const shippingZonesList = useSelector(state => valuesListSelector(state, 'shippingZoneName'));
    const deliveryZonesList = useSelector(state => valuesListSelector(state, 'deliveryZoneName'));

    const reportProgress = useSelector(progressSelector);
    const exportProgress = useSelector(exportProgressSelector);
    
    useEffect(() => {
        dispatch(
            getLookupRequest({
                entities: `reports/${reportType}`,
                name: 'shippingNumber',
                isForm: true,
                isFilter: true,
                params: {
                    ...params,
                    shippingNumbers: []
                }
            }),
        );
        dispatch(
            getLookupRequest({
                name: 'orders',
                isForm: true
            }),
        );
        dispatch(
            getLookupRequest({
                name: 'transportCompanies',
                isForm: true
            }),
        );
    }, []);

    useEffect(() => {
        if (params['directionType'].value === 'city' ) {
            dispatch(
                getLookupRequest({
                    entities: `reports/${reportType}`,
                    name: 'shippingCity',
                    isForm: true,
                    isFilter: true,
                    params: {
                        ...params,
                        from: []
                    }
                }),
            );
            dispatch(
                getLookupRequest({
                    entities: `reports/${reportType}`,
                    name: 'deliveryCity',
                    isForm: true,
                    isFilter: true,
                    params: {
                        ...params,
                        to: []
                    }
                }),
            );
        }
        else if (params['directionType'].value === 'region' ) {
            dispatch(
                getLookupRequest({
                    entities: `reports/${reportType}`,
                    name: 'shippingRegion',
                    isForm: true,
                    isFilter: true,
                    params: {
                        ...params,
                        from: []
                    }
                }),
            );
            dispatch(
                getLookupRequest({
                    entities: `reports/${reportType}`,
                    name: 'deliveryRegion',
                    isForm: true,
                    isFilter: true,
                    params: {
                        ...params,
                        to: []
                    }
                }),
            );
        }
        else if (params['directionType'].value === 'transportZone' ) {
            dispatch(
                getLookupRequest({
                    entities: `reports/${reportType}`,
                    name: 'shippingZoneName',
                    isForm: true,
                    isFilter: true,
                    params: {
                        ...params,
                        from: []
                    }
                }),
            );
            dispatch(
                getLookupRequest({
                    entities: `reports/${reportType}`,
                    name: 'deliveryZoneName',
                    isForm: true,
                    isFilter: true,
                    params: {
                        ...params,
                        to: []
                    }
                }),
            );
        }
        dispatch(
            getLookupRequest({
                entities: `reports/${reportType}`,
                name: 'shippingNumber',
                isForm: true,
                isFilter: true,
                params: {
                    ...params,
                    shippingNumbers: []
                }
            }),
        );
    }, [params]);

    const handleChangeParams = (e, { name, value }) => {
        const newParams = {
            ...params,
            [name]: value
        };
        
        if (name === 'directionType') {
            newParams['to'] = [];
            newParams['from'] = [];
        }
        
        setParams(newParams);
    };

    return (
        <div className="report_params">
            <Form>
                <Grid>
                    <Grid.Row columns={4}>
                        <Grid.Column>
                            <Select
                                clearable={false}
                                name="dateType"
                                text={t('reports.dateType')}
                                source='reportPeriodDateType'
                                value={params.dateType}
                                onChange={handleChangeParams}
                                isDisabled={reportType === ALLOCATIONS_PLAN_ACTUAL_INDICATORS_REPORT_TYPE || reportType === DIRECTION_ALLOCATIONS_PLAN_ACTUAL_REPORT_TYPE || reportType === REFUSAL_REPORT_TYPE}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Date
                                name="startDate"
                                value={params.startDate}
                                onChange={handleChangeParams}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Date
                                name="endDate"
                                isDisabled={params['dateType'].value === 'reportPeriodAllocationDateType'}
                                value={params.endDate}
                                onChange={handleChangeParams}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <div className="field">
                                <label>
                                    <span>{t('reports.shippingNumber')}</span>
                                </label>
                                <Dropdown
                                    selection
                                    multiple
                                    search
                                    options={shippingNumberList.map(p => ({
                                        key: p.value,
                                        text: p.name,
                                        value: p.value
                                    }))}
                                    name="shippingNumbers"
                                    value={params.shippingNumbers}
                                    onChange={handleChangeParams}
                                />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={4}>
                        <Grid.Column>
                            <div className="field">
                                <label>
                                    <span>{t('reports.orderNumber')}</span>
                                </label>
                                <Dropdown
                                    selection
                                    multiple
                                    search
                                    options={(ordersSelectList.length ? ordersSelectList : ordersList)?.map(p => ({
                                        key: p.value,
                                        text: p.name,
                                        value: p.value
                                    }))}
                                    name="orderNumbers"
                                    value={params.orderNumbers}
                                    onChange={handleChangeParams}
                                >
                                </Dropdown>
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <Select
                                clearable={false}
                                name="directionType"
                                text={t('reports.directionType')}
                                source='reportDirectionType'
                                value={params.directionType}
                                onChange={handleChangeParams}
                                isDisabled={reportType === REFUSAL_REPORT_TYPE}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <div className="field">
                                <label>
                                    <span>{t('reports.from')}</span>
                                </label>
                                <Dropdown
                                    selection
                                    multiple
                                    name="from"
                                    search
                                    value={params.from}
                                    options={(
                                        params['directionType'].value === 'city' 
                                        ? shippingCitiesList 
                                        : params['directionType'].value === 'region'
                                            ? shippingRegionsList
                                            : params['directionType'].value === 'transportZone'
                                                ? shippingZonesList
                                                : []
                                    )?.map(p => ({
                                        key: p.value,
                                        text: p.name,
                                        value: p.value
                                    }))}
                                    onChange={handleChangeParams}
                                >
                                </Dropdown>
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <div className="field">
                                <label>
                                    <span>{t('reports.to')}</span>
                                </label>
                                <Dropdown
                                    selection
                                    multiple
                                    name="to"
                                    search
                                    value={params.to}
                                    options={(
                                        params['directionType'].value === 'city'
                                            ? deliveryCitiesList
                                            : params['directionType'].value === 'region'
                                                ? deliveryRegionsList
                                                : params['directionType'].value === 'transportZone'
                                                    ? deliveryZonesList
                                                    : []
                                    )?.map(p => ({
                                        key: p.value,
                                        text: p.name,
                                        value: p.value
                                    }))}
                                    onChange={handleChangeParams}
                                >
                                </Dropdown>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <div className={'field' + (reportType === REFUSAL_REPORT_TYPE ? ' disabled' : '')}>
                                <label>
                                    <span>{t('reports.factCarrier')}</span>
                                </label>
                                <Dropdown
                                    selection
                                    multiple
                                    name="factCarriers"
                                    search
                                    options={carriersList?.map(p => ({
                                        key: p.value,
                                        text: p.name,
                                        value: p.value
                                    }))}
                                    onChange={handleChangeParams}
                                    disabled={reportType === REFUSAL_REPORT_TYPE}
                                >
                                </Dropdown>
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <div className={'field' + (reportType === REFUSAL_REPORT_TYPE ? ' disabled' : '')}>
                                <label>
                                    <span>{t('reports.historyCarriers')}</span>
                                </label>
                                <Dropdown
                                    selection
                                    multiple
                                    search
                                    name="historyCarriers"
                                    options={carriersList?.map(p => ({
                                        key: p.value,
                                        text: p.name,
                                        value: p.value
                                    }))}
                                    onChange={handleChangeParams}
                                    disabled={reportType === REFUSAL_REPORT_TYPE}
                                >
                                </Dropdown>
                            </div>
                        </Grid.Column>
                        <Grid.Column verticalAlign="bottom" textAlign="right">
                            <Button primary onClick={onGenerateReport} disabled={reportProgress}>
                                <Dimmer active={reportProgress} inverted>
                                    <Loader size="small"></Loader>
                                </Dimmer>
                                Сформировать
                            </Button>
                            <Button primary onClick={onExportExcel} disabled={exportProgress}>
                                <Dimmer active={exportProgress} inverted>
                                    <Loader size="small"></Loader>
                                </Dimmer>
                                Скачать
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        </div>
    );
};

export default ReportFilters;
