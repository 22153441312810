import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown, Form, Icon, Popup, Visibility } from 'semantic-ui-react';

import './style.scss';
import {
    getLookupRequest,
    listFromSelectSelector,
    totalCounterSelector,
} from '../../ducks/lookup';
import { PAGE_SIZE } from '../../constants/settings';
import { toast } from 'react-toastify';

const Select = ({
    value = {},
    onChange,
    placeholder = '',
    isDisabled,
    hintKey,
    name,
    text,
    source,
    isTranslate,
    error,
    noLabel,
    isRequired,
    isPartialSelect,
    listboxMaxHeight,
    children,
    upward,
    isBulkUpdateOnly,
    sourceParams,
    isGrid,
    formRef,
    compact,
    clearable = true
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const context = useRef(null);
    const selectRef = useRef(null);
    let timer = useRef(null);

    let [open, setOpen] = useState(false);
    let [counter, setCounter] = useState(PAGE_SIZE);
    let [searchQuery, setSearchQuery] = useState('');
    let [filter, setFilter] = useState('');

    const valuesList = useSelector(state =>
        listFromSelectSelector(state, source, t, filter, isTranslate, counter, isBulkUpdateOnly),
    );
    const totalCounter = useSelector(state =>
        totalCounterSelector(state, source, t, filter, isTranslate),
    );
    const progress = false;

    useEffect(() => {
        isPartialSelect && open && getLookup();
    }, [filter, counter]);

    useEffect(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            setFilter(searchQuery && searchQuery.trim());
        }, 300);
    }, [searchQuery]);

    useEffect(() => {
        context.current.scrollTop = 0;
        setCounter(PAGE_SIZE);
    }, [filter]);

    useEffect(() => {
        setTimeout(() => {
            if (isGrid && selectRef && selectRef.current) {
               selectRef.current.handleFocus();
            }
        }, 100)
    }, []);

    const handleChange = (e, { value }) => {
        setSearchQuery('');
        toggle(false);
        onChange(e, { value: value ? value : null, name });
        handleClose();

        if (isGrid) {
            setTimeout(() => {
                formRef &&
                formRef.current &&
                formRef.current.handleSubmit &&
                formRef.current.handleSubmit();
            }, 100);
        }
    };

    const getLookup = () => {
        let requestSourceParams = isPartialSelect
            ? {
                ...sourceParams,
                search: filter,
                limit: counter
            }
            : sourceParams;
        dispatch(
            getLookupRequest({
                name: source,
                isForm: true,
                sourceParams: requestSourceParams,
            }),
        );
    };

    const handleOpen = () => {
        getLookup();
        toggle(true);
    };

    const handleClose = () => {
        context.current.scrollTop = 0;
        setCounter(PAGE_SIZE);
    };

    const handleBlur = () => {
        toggle(false);
        setSearchQuery('');
    };

    const toggle = value => {
        setOpen(value);
    };

    const scroll = () => {
        if (counter < totalCounter) {
            setCounter(prevState => prevState + PAGE_SIZE);
        }
    };

    const handleSearchChange = (e, { searchQuery }) => {
        setSearchQuery(searchQuery);
    };

    const handleFocus = () => {
        toggle(true);
        handleOpen();
    };

    const copyToClipboard = () => {
        value && 
            value.name && 
            navigator.clipboard &&
            navigator.clipboard.writeText(value.name).then(
                () => {
                    toast.info(t('copied_to_clipboard_success'));
                },
                error => {
                    toast.error(t('copied_to_clipboard_error', { error }));
                },
            );
    };

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${t(text || name)}${isRequired ? ' *' : ''}`,
                        }}
                    />
                    <Popup 
                        content={t('copy_value')} 
                        trigger={
                            <div className="field-attr">
                                <div className="cell-grid-copy-btn">
                                    <Icon
                                        name="clone outline"
                                        size="small"
                                        onClick={copyToClipboard}
                                    />
                                </div>
                            </div>
                        } 
                        position='bottom left' 
                    /> 
                </label>
            ) : null}
            <div className={`form-select ${isDisabled ? 'form-select_disabled' : ''}`}>
                <Dropdown
                    ref={selectRef}
                    placeholder={t(placeholder)}
                    fluid
                    selection={!isGrid}
                    loading={progress}
                    search={!isGrid}
                    clearable={clearable && value && value.value}
                    value={value && value.value}
                    searchQuery={searchQuery}
                    text={value ? t(value.name) : null}
                    error={error}
                    disabled={isDisabled}
                    closeOnChange={true}
                    closeOnBlur={true}
                    onBlur={handleBlur}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    onSearchChange={handleSearchChange}
                    onFocus={handleFocus}
                    open={open}
                    upward={upward}
                    onChange={handleChange}
                    compact={compact}
                >
                    <div role="listbox" style={listboxMaxHeight ? {maxHeight: listboxMaxHeight} : {}} className={`menu transition${isGrid ? ' select_grid' : ''}`} ref={context}>
                        {valuesList && valuesList.length ? (
                            valuesList.map(item => (
                                <Dropdown.Item
                                    style={item.isBulkUpdateOnly ? { color: '#9a0000' } : {}}
                                    key={item.value}
                                    selected={value && item.value === value.value}
                                    active={value && item.value === value.value}
                                    value={item.value}
                                    onClick={e => handleChange(e, { value: item })}
                                >
                                    {t(item.name)}
                                </Dropdown.Item>
                            ))
                        ) : (
                            <div className="message">No results found.</div>
                        )}
                        <Visibility
                            continuous={false}
                            once={false}
                            context={context.current}
                            onTopVisible={scroll}
                        />
                    </div>
                </Dropdown>
                {children && children}
            </div>
            {hintKey && <span className="label-hint">{t(hintKey)}</span>}
            {hintKey && error && typeof error === 'string' && <br/>}
            {error && typeof error === 'string' && <span className="label-error">{error}</span>}
        </Form.Field>
    );
};

export default React.memo(Select);
