import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CardLayout from '../../../components/CardLayout';
import { Button, Confirm, Icon, Modal, Popup } from 'semantic-ui-react';
import Content from './cardContent';
import ScheduleTab from './warehouses/scheduleTab';
import {
    canDeleteSelector,
    canEditSelector,
    cardProgressSelector,
    cardSelector,
    clearDictionaryCard,
    columnsCardSelector,
    deleteDictionaryEntryRequest,
    errorSelector,
    getCardConfigRequest,
    getCardRequest,
    getDictionaryCardDefaultValueRequest,
    progressSelector,
    saveDictionaryCardRequest,
    canUseDocumentsSelector,
    canUseHistorySelector,
    displayNameSelector,
} from '../../../ducks/dictionaryView';
import { uploadProgressSelector } from '../../../ducks/documents';
import Vehicles from "./drivers/vehicles";
import History from '../../customGrid/components/shared/history';
import { clearHistory, getHistoryRequest } from '../../../ducks/history';
import { isCustomPageSelector } from '../../../ducks/profile';
import Documents from '../../customGrid/components/shared/documents';
import TerminalBandwidthParameters from './warehouses/terminalBandwidthParameters';
import TariffAdditionalCosts from '../tariffs/tariffTabs/tariffAdditionalCosts';
import {columnIsVisible} from "../../../utils/columnVisible";

const CardNew = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        match = {},
        history,
        location,
        load,
        isModal,
        openModal,
        onClose: onCloseModal,
    } = props;
    const { params = {} } = match;
    const { name, id } = params;
    const { state } = location;
    const { defaultForm, columns: propsColumns, copyFromId } = (state || {});

    let [form, setForm] = useState({ ...defaultForm });
    let [confirmation, setConfirmation] = useState({ open: false });
    let [notChangeForm, setNotChangeForm] = useState(true);
    let [dependentFields, setDependentFields] = useState({});

    const columns = useSelector(state =>
        propsColumns ? propsColumns : columnsCardSelector(state, name, id),
    );
    const displayName = useSelector(state => displayNameSelector(state, name));
    const canDelete = useSelector(state => canDeleteSelector(state, name));
    const canEdit = useSelector(state => canEditSelector(state, name));
    const useDocuments = useSelector(state => canUseDocumentsSelector(state, name));
    const useHistory = useSelector(state => canUseHistorySelector(state, name));
    const loading = useSelector(state => cardProgressSelector(state));
    const progress = useSelector(state => progressSelector(state));
    const card = useSelector(state => cardSelector(state));
    const error = useSelector(state => errorSelector(state));
    const uploading = useSelector(state => uploadProgressSelector(state));
    const pageRights = useSelector(state => isCustomPageSelector(state));

    useEffect(() => {
        id && dispatch(getCardConfigRequest({ id, name }));
        id
            ? dispatch(getCardRequest({ id, name }))
            : copyFromId
                ? dispatch(getCardRequest({ id: copyFromId, name }))
                : dispatch(getDictionaryCardDefaultValueRequest(name));

        return () => {
            dispatch(clearDictionaryCard());
        };
    }, []);

    useEffect(() => {
        if (useHistory) {
            id && dispatch(getHistoryRequest(id));
        }

        return () => {
            dispatch(clearHistory());
        };
    }, [useHistory]);

    useEffect(() => {
        if (copyFromId) {
            card.id = null;
            setNotChangeForm(false);
        }
        setForm(form => ({
            ...card,
            ...form,
        }));
    }, [card]);

    useEffect(() => {
        let obj = {};
        columns && columns.length && columns
            .filter(column => column.dependencies && column.dependencies.length)
            .forEach(column => {
                column.dependencies.forEach(item => {
                    obj = {
                        ...obj,
                        [item]: [...(obj[item] || []), column.name],
                    };
                });
            });

        setDependentFields(obj);
    }, [columns]);

    const onOpenModal = () => {};

    const showConfirmation = (content, onConfirm, onCancel) => {
        setConfirmation({
            open: true,
            content,
            onConfirm,
            onCancel,
        });
    };

    const title = useMemo(
        () => (id ? `${displayName || t(name)}: ${t('edit_record')}` : `${displayName || t(name)}: ${t('new_record')}`),
        [name, id],
    );

    const getActionsFooter = useCallback(() => {
        return (
            <>
                <Button color="grey" onClick={handleClose}>
                    {t('CancelButton')}
                </Button>
                <Button
                    color="blue"
                    disabled={notChangeForm || progress}
                    loading={progress}
                    onClick={handleSave}
                >
                    {t('SaveButton')}
                </Button>
            </>
        );
    }, [form, notChangeForm, progress]);

    const handleSave = () => {
        let params = {
            ...form,
        };

        if (id) {
            params = {
                ...params,
                id,
            };
        }

        const callbackConfirmation = message => {
            setConfirmation({
                open: true,
                content: message,
                onCancel: confirmClose,
                onConfirm: () => {
                    confirmClose();
                    dispatch(
                        saveDictionaryCardRequest({
                            params,
                            name,
                            callbackSuccess: () => {
                                load && load(form);
                                onClose();
                            },
                            isConfirmed: true,
                        }),
                    );
                },
            });
        };

        dispatch(
            saveDictionaryCardRequest({
                params,
                name,
                callbackSuccess: () => {
                    load && load(form);
                    onClose();
                },
                isConfirmed: false,
                callbackConfirmation,
            }),
        );
    };

    const handleDelete = () => {
        /* const { id, deleteEntry, name } = this.props;*/

        showConfirmation(
            t('confirm_delete_dictionary'),
            () => {
                confirmClose();
                dispatch(
                    deleteDictionaryEntryRequest({
                        name,
                        ids: [ id ],
                        callbackSuccess: onClose,
                    }),
                );
            },
            confirmClose,
        );
    };

    const getActionsHeader = useCallback(() => {
        return (
            <div>
                {canDelete ? (
                    <Popup
                        content={t('delete')}
                        position="bottom right"
                        trigger={
                            <Button icon onClick={handleDelete}>
                                <Icon name="trash alternate outline" />
                            </Button>
                        }
                    />
                ) : null}
            </div>
        );
    }, []);

    const handleChange = useCallback(
        (event, { name, value, ...prev }) => {
            if (notChangeForm) {
                setNotChangeForm(false);
            }
            let formNew = {
                ...form,
                [name]: value,
            };

            if (dependentFields[name] && dependentFields[name].length) {
                dependentFields[name].forEach(item => {
                    formNew = {
                        ...formNew,
                        [item]: "",
                    }
                })
            }

            const autocompleteFields = columns.filter(
                i => i.autoComplete && Object.keys(i.autoComplete).includes(name),
            );

            if (autocompleteFields && autocompleteFields.length && value) {
                autocompleteFields.forEach(autocompleteField => {
                    formNew = {
                        ...formNew,
                        [autocompleteField.name]: value[autocompleteField.autoComplete[name]] || '',
                    };
                });
            }

            setForm(formNew);
        },
        [notChangeForm, form, dependentFields, columns],
    );

    const confirmClose = () => {
        setConfirmation({ open: false });
    };

    const onClose = () => {
        isModal
            ? onCloseModal()
            : history.replace({
                  pathname: location.state.pathname,
                  state: {
                      ...location.state,
                      pathname: location.state.gridLocation
                          ? location.state.gridLocation
                          : location.state.pathname,
                  },
              });
    };

    const handleClose = () => {
        if (notChangeForm) {
            onClose();
        } else {
            setConfirmation({
                open: true,
                content: t('confirm_close_dictionary'),
                onCancel: confirmClose,
                onConfirm: onClose,
            });
        }
    };

    const getContent = useCallback(() => {
        let baseColumns = columns.filter(column => !column.groupKey);

        let items = [
            {
                menuItem: 'information',
                render: () => (
                    <Content 
                        columns={baseColumns.filter(column => column.name !== "schedule" && column.name !== "scheduleTitle")} 
                        error={error} 
                        form={form} 
                        canEdit={canEdit}
                        handleChange={handleChange} 
                    />
                ),
            }
        ];

        let groups = Array.from(new Set(columns.filter(column => column.groupKey).map((column) => column.groupKey)));
        groups.forEach(groupKey => {
            let groupColumns = columns.filter(column => column.groupKey === groupKey && columnIsVisible(column, form, !notChangeForm, handleChange));
            items.push({
                menuItem: groupKey,
                disabled: !groupColumns.length,
                defaultOpen: !!groupColumns.length,
                render: () => (
                    <Content 
                        columns={groupColumns} 
                        error={error} 
                        form={form} 
                        canEdit={canEdit}
                        handleChange={handleChange} 
                    />
                ),
            });
        });

        if (baseColumns.find(item => item.name === "scheduleTitle")) {
            items.push({
                menuItem: 'Warehouse.Schedule',
                render: () => (
                    <ScheduleTab columns={baseColumns} error={error} form={form} onChange={handleChange} />
                ),
            });
        } else if (name === 'drivers' && pageRights && pageRights.editDriverVehicles) {
            items.push({
                menuItem: 'vehicles',
                render: () => (
                    <Vehicles error={error} form={form} onChange={handleChange} />
                ),
            });
        }

        if (name === 'warehouses' && pageRights && pageRights.editTerminalBandwidth) {
            items.push({
                menuItem: 'terminalBandwidthParameters',
                render: () => (
                    <TerminalBandwidthParameters
                        form={form}
                        onChange={handleChange}
                        gridName={name}
                        error={error}
                    />
                )
            });
        }

        if (name === 'tariffs' && pageRights && pageRights.editTariffAdditionalCosts) {
            items.push({
                menuItem: 'tariffAdditionalCosts',
                render: () => (
                    <TariffAdditionalCosts
                        form={form}
                        onChange={handleChange}
                        gridName={name}
                        formColumns={baseColumns}
                        error={error}
                    />
                ),
            });
        }

        if (useDocuments) {
            items.push({
                menuItem: 'documents',
                render: () => (
                    <Documents
                        gridName={name}
                        cardId={id}
                        isEditPermissions={canEdit}
                    />
                ),
            });
        }

        if (useHistory) {
            items.push({
                menuItem: 'history',
                render: () => <History cardId={id} />,
            });
        }

        return items;
    }, [form, error, columns, useDocuments, useHistory]);

    return (
        <>
            {isModal ? (
                <Modal
                    dimmer="blurring"
                    open={openModal}
                    closeOnDimmerClick={false}
                    onOpen={onOpenModal}
                    onClose={onCloseModal}
                    closeIcon
                >
                    <Modal.Header>{title}</Modal.Header>
                    <Modal.Description>
                        {/*<Loader size="huge" active={loading}>
                            Loading
                        </Loader>*/}
                        <Content
                            columns={columns}
                            error={error}
                            form={form}
                            canEdit={canEdit}
                            handleChange={handleChange}
                        />
                    </Modal.Description>
                    <Modal.Actions>{getActionsFooter()}</Modal.Actions>
                </Modal>
            ) : (
                <CardLayout
                    title={title}
                    actionsFooter={getActionsFooter}
                    actionsHeader={getActionsHeader}
                    onClose={handleClose}
                    content={getContent}
                    loading={loading}
                />
            )}
            <Confirm
                dimmer="blurring"
                open={confirmation.open}
                onCancel={confirmation.onCancel}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('Yes')}
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
        </>
    );
};

export default CardNew;
