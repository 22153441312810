import React, { useRef, useState } from 'react';
import {
    Button,
    Confirm,
    Dropdown,
    Form,
    Grid,
    Icon,
    Menu,
    Message,
    Modal,
    Popup
} from 'semantic-ui-react';
import Search from '../../../components/Search';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    canExportToExcelSelector,
    canImportFromExcelSelector,
    exportProgressSelector,
    exportToExcelRequest,
    exportHistoryToExcelRequest,
    importFromExcelRequest,
    importProgressSelector,
    exportCarrierDocumentsRequest, exportRegistryXlsxRequest, exportRegistryPdfRequest,
} from '../../../ducks/gridList';

import CustomIcon from '../../CustomIcon'

import FieldsConfig from './representations';
import {
    getRepresentationsRequest,
    representationsSelector,
    setRepresentationRequest,
} from '../../../ducks/representations';
import {ORDERS_GRID, REGISTERS_GRID, SHIPPINGS_GRID} from "../../../constants/grids";
import {ordersLoadingSelector} from "../../../ducks/loadingData";
import Date from "../../BaseComponents/Date";
import {calculationDeliveryRequest} from "../../../ducks/gridCard";
import {dateToString} from "../../../utils/dateTimeFormater";
import {userPermissionsSelector} from "../../../ducks/profile";
import FileModal from "../../Documents/FileModal";
import {addShippingsDocumentRequest, progressSelector} from "../../../ducks/documents";

const Header = ({
    bookmarks,
    selectedBookmark,
    handleBookmarkSelection,
    isCreateBtn,
    isCopyBtn,
    isExportHistoryBtn,
    isDeleteBtn,
    isCalculateDeliveryToForwarder,
    isExportCarrierDocuments,
    searchValue,
    searchOnChange,
    counter,
    clearFilter,
    disabledClearFilter,
    loadList,
    name,
    setSelected,
    deleteEntries,
    representationName,
    filter,
    goToCard,
    width,
    pageLoading,
    hideBacklights,
    toggleShowBacklights,
    selectedRows
}) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const fileUploader = useRef(null);

    const defaultModalValue = { open: false, from: dateToString(), to: dateToString() };
    let [openModal, setOpenModal] = useState(defaultModalValue);
    let [fileModalOpen, setFileModalOpen] = useState(false);

    const isImportBtn = useSelector(state => canImportFromExcelSelector(state, name));
    const isExportBtn = useSelector(state => canExportToExcelSelector(state, name));

    const isFileProgress = useSelector(state => progressSelector(state));

    const importLoader = useSelector(state => importProgressSelector(state));
    const exportLoader = useSelector(state => exportProgressSelector(state));
    const loadingOrders = useSelector(ordersLoadingSelector);
    const userPermissions = useSelector(state => userPermissionsSelector(state));

    const [confirmation, setConfirmation] = useState({ open: false });

    const representations = useSelector(state => representationsSelector(state, name));

    const closeConfirmation = () => {
        setConfirmation({ open: false });
    };

    const showConfirmation = (content, onConfirm, onCancel) => {
        setConfirmation({
            open: true,
            content,
            onConfirm,
            onCancel,
        });
    };

    const exportExcel = () => {
        dispatch(exportToExcelRequest({ name, filter: filter.filter, hideBacklights, ids: Array.from(selectedRows) }));
    };

    const exportHistoryExcel = (historyType) => {
        dispatch(exportHistoryToExcelRequest({ name, ids: Array.from(selectedRows), historyType }));
    };

    const exportCarrierDocuments = () => {
        dispatch(exportCarrierDocumentsRequest({ name, ids: Array.from(selectedRows) }));
    };

    const exportRegistryXlsx = () => {
        dispatch(exportRegistryXlsxRequest({ name, ids: Array.from(selectedRows) }));
    };

    const exportRegistryPdf = () => {
        dispatch(exportRegistryPdfRequest({ name, ids: Array.from(selectedRows) }));
    };
    const importExcel = () => {
        fileUploader && fileUploader.current.click();
    };

    const handleCloseFileModal = () => {
        setFileModalOpen(false);
    };

    const handleSaveFileModal = (document, handleClose) => {
        if (document && document.fileId) {
            dispatch(
                addShippingsDocumentRequest({
                    ids: Array.from(selectedRows),
                    document: {
                        name: document.name,
                        fileId: document.fileId,
                        type: document.type,
                        number: document.number,
                        date: document.date,
                    },
                    callbackSuccess: () => {
                        handleClose();
                    },
                }),
            );
        }
    };

    const onFilePicked = e => {
        const file = e.target.files[0];

        const data = new FormData();
        data.append('FileName', file.name);
        data.append('FileContent', new Blob([file], { type: file.type }));
        data.append('FileContentType', file.type);

        dispatch(
            importFromExcelRequest({
                name,
                form: data,
                callbackSuccess: () => loadList(false, true),
            }),
        );
    };

    const getRepresentations = callBackFunc => {
        dispatch(getRepresentationsRequest({ key: name, callBackFunc }));
    };

    const changeRepresentation = (key, isEdit) => {
        dispatch(
            setRepresentationRequest({
                gridName: name,
                value: key,
                callbackSuccess: () => {
                    setSelected(new Set());
                    pageLoading && pageLoading();
                },
            }),
        );
    };

    const handleGoToCard = () => {
        goToCard(false, null, name);
    };

    const handleCopyEntry = () => {
        const id = Array.from(selectedRows)[0];
        goToCard(false, null, name, id);
    };

    const handleDeleteEntries = () => {
        showConfirmation(
            t('confirm_delete_entries').replace(':DICT', t(name)),
            () => {
                closeConfirmation();
                deleteEntries && deleteEntries({
                    name,
                    ids: Array.from(selectedRows),
                    callbackSuccess: () => {
                        loadList(false, true);
                        setSelected(new Set());
                    }
                });
            },
            closeConfirmation,
        );
    };

    const handleCalculationDelivery = () => {
        dispatch(
            calculationDeliveryRequest({
                params: {
                    from: openModal.from,
                    to: openModal.to,
                },
                callbackSuccess: () => {
                    loadList(false, true);
                    setOpenModal(defaultModalValue);
                }
            }),
        );
    };

    const handleChangeCalculationDeliveryModal = value => {
        setOpenModal(prevState => ({
            ...prevState,
            ...value,
        }));
    };

    return (
        <Grid className="grid-header-panel">
            {bookmarks && bookmarks.length > 0 ? (
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Menu compact>
                            <Menu.Item
                                name='all'
                                content={t('All')}
                                active={!selectedBookmark}
                                onClick={() => handleBookmarkSelection('')}
                            />
                            {bookmarks.map((entry) => (
                                <Menu.Item
                                    name={entry.value}
                                    content={entry.name}
                                    active={selectedBookmark === entry.value}
                                    onClick={() => handleBookmarkSelection(entry.value)}
                                />
                            ))}
                        </Menu>
                    </Grid.Column>
                </Grid.Row>
            ) : null}
            <Grid.Row>
                <Grid.Column width={5}>
                    <FieldsConfig
                        gridName={name}
                        width={width}
                        representationName={representationName}
                        getRepresentations={getRepresentations}
                        changeRepresentation={changeRepresentation}
                        representations={representations}
                    />
                </Grid.Column>
                <Grid.Column width={1} verticalAlign="middle">
                    <span className="records-counter">{t('totalCount', { count: counter })}</span>
                </Grid.Column>
                <Grid.Column width={3}>
                    {
                        name === ORDERS_GRID && loadingOrders
                        ? <Message info>
                            <Icon name="clock"/>
                                Идет загрузка данных...
                            </Message>
                        : null
                    }

                </Grid.Column>
                <Grid.Column width={7} className="grid-right-elements">
                    <Popup
                        content={t('show_backlights')}
                        position="bottom right"
                        trigger={
                            <Button color={hideBacklights ? undefined : 'blue'}
                                icon="lightbulb outline"
                                onClick={toggleShowBacklights}
                            />
                        }
                    />
                    {isCreateBtn && (
                        <Popup
                            content={t('add_record')}
                            position="bottom right"
                            trigger={<Button icon="add" onClick={handleGoToCard} />}
                        />
                    )}
                    {isCopyBtn ? (
                        <Popup
                            content={t('copyEntry')}
                            position="bottom right"
                            trigger={
                                <Button
                                    icon="copy"
                                    onClick={handleCopyEntry}
                                    disabled={selectedRows.size !== 1}
                                />
                            }
                        />
                    ) : null}
                    {isExportHistoryBtn && (
                        <Popup
                            content={
                                t('exportHistoryToExcel')
                            }
                            position="left center"
                            trigger={
                                name !== SHIPPINGS_GRID
                                    ? <Button
                                        icon="history"
                                        loading={exportLoader}
                                        onClick={() => exportHistoryExcel()}
                                        disabled={!selectedRows.size}
                                    />
                                    : <Dropdown
                                        icon='history'
                                        floating
                                        button
                                        loading={exportLoader}
                                        disabled={!selectedRows.size}
                                        className='icon'
                                    >
                                        <Dropdown.Menu>
                                            {
                                                ['General', 'CostHistory', 'Requests'].map(v => <Dropdown.Item
                                                    disabled={exportLoader}
                                                    content={t(`exportHistory${v}`)}
                                                    onClick={() => exportHistoryExcel(v)}
                                                />)
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                            }
                        />
                    )}
                    {name === SHIPPINGS_GRID && isCalculateDeliveryToForwarder && (
                        <Popup
                            content={t('Calculation of delivery to the forwarder is warehouse')}
                            position="bottom right"
                            trigger={<Button
                                icon="calculator"
                                onClick={() => handleChangeCalculationDeliveryModal({ open: true })}
                            />}
                        />
                    )}
                    {name === SHIPPINGS_GRID && isExportCarrierDocuments && (
                        <Popup
                            content={t('exportCarrierDocuments')}
                            position="bottom right"
                            trigger={<Button
                                icon="file archive" 
                                loading={exportLoader}
                                onClick={exportCarrierDocuments} 
                                disabled={!selectedRows.size}
                            />}
                        />
                    )}
                    {name === REGISTERS_GRID && (userPermissions.includes(132) || userPermissions.includes(133)) && (
                        <Dropdown
                            icon='print'
                            floating
                            button
                            loading={exportLoader}
                            disabled={!selectedRows.size}
                            className='icon'>
                            <Dropdown.Menu>
                                {userPermissions.includes(132) && <Dropdown.Item
                                    disabled={exportLoader}
                                    content={t('exportRegistersPdf')}
                                    onClick={exportRegistryPdf}
                                />}
                                {userPermissions.includes(133) && <Dropdown.Item
                                    disabled={exportLoader}
                                    content={t('exportRegistersXlsx')}
                                    onClick={exportRegistryXlsx}
                                />}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                    {name === SHIPPINGS_GRID && userPermissions.includes(134) && (
                        <>
                            <Popup
                                content={t('uploadShippingDocuments')}
                                position="bottom right"
                                trigger={
                                    <Button
                                        icon="upload"
                                        disabled={!selectedRows.size}
                                        onClick={() => setFileModalOpen(true)}
                                    />
                                }
                            />
                            <FileModal
                                titleText={t('Edit document')}
                                okButtonText={t('SaveButton')}
                                modalOpen={fileModalOpen}
                                onClose={handleCloseFileModal}
                                onSave={handleSaveFileModal}
                                disabled={isFileProgress}
                            />
                        </>
                    )}
                    {isImportBtn && (
                        <Popup
                            content={t('importFromExcel')}
                            position="bottom right"
                            trigger={
                                <Button
                                    icon="upload"
                                    loading={importLoader}
                                    onClick={importExcel}
                                />
                            }
                        />
                    )}
                    {isExportBtn && (
                        <Popup
                            content={
                                t('exportToExcel') // todo
                            }
                            position="bottom right"
                            trigger={
                                <Button
                                    icon="download"
                                    loading={exportLoader}
                                    onClick={exportExcel}
                                />
                            }
                        />
                    )}
                    {isDeleteBtn ? (
                        <Popup
                            content={t('deleteEntries')}
                            position="bottom right"
                            trigger={
                                <Button
                                    icon="trash alternate"
                                    onClick={handleDeleteEntries}
                                    disabled={!selectedRows.size}
                                />
                            }
                        />
                    ) : null}
                    <Popup
                        content={t('reset_filters')}
                        position="bottom right"
                        trigger={
                            <Button
                                icon
                                className={`clear-filter-btn`}
                                onClick={clearFilter}
                                disabled={disabledClearFilter}
                            >
                                <CustomIcon name="clear-filter" />
                            </Button>
                        }
                    />
                    <Search
                        searchValue={searchValue}
                        className="search-input"
                        value={filter.filter.filter.search}
                        onChange={searchOnChange}
                    />
                </Grid.Column>
            </Grid.Row>
            <input
                type="file"
                ref={fileUploader}
                style={{ display: 'none' }}
                onInput={onFilePicked}
            />
            <Modal open={openModal.open}>
                <Modal.Header>{t('Select dates')}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <Form.Field>
                                        <Date
                                            value={openModal.from}
                                            name="from"
                                            onChange={(e, { value }) =>
                                                handleChangeCalculationDeliveryModal({
                                                    from: value,
                                                })
                                            }
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <Date
                                            value={openModal.to}
                                            name="to"
                                            onChange={(e, { value }) =>
                                                handleChangeCalculationDeliveryModal({ to: value })
                                            }
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setOpenModal(defaultModalValue)}>
                        {t('CancelButton')}
                    </Button>
                    <Button primary onClick={handleCalculationDelivery}>
                        {t('Calculate')}
                    </Button>
                </Modal.Actions>
            </Modal>
            <Confirm
                dimmer="blurring"
                open={confirmation.open}
                onCancel={confirmation.onCancel || closeConfirmation}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('Yes')}
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
        </Grid>
    );
};

export default Header;
