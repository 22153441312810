import React, { useEffect, useRef } from 'react';
import { dataLoadingRequest } from '../../ducks/loadingData';
import {configSelector, downloadDocumentRequest, uploadConfigRequest, uploadFileRequest} from '../../ducks/documents';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Form, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';

const FilesList = ({
    value,
    name,
    isDisabled,
    noLabel,
    text,
    isRequired
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const handleDownload = (item) => {
        item && item.value && dispatch(downloadDocumentRequest({
            id: item.value
        }))
    };

    return (
        <>
            <Form.Field>
                {!noLabel ? (
                    <label className={isDisabled ? 'label-disabled' : null}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: `${t(text || name)}${isRequired ? ' *' : ''}`,
                            }}
                        />
                    </label>
                ) : null}
                {value && value.map((item) => (
                    <div className="field-file">
                        <div className="field-file_link"
                            onClick={() => handleDownload(item)}>
                            {item && typeof item === 'object' ? item.name : item}
                        </div>
                    </div>
                ))}
            </Form.Field>
        </>
    );
};

export default FilesList;
