import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    allActionsSelector, 
    allBacklightsSelector,
    allPermissionsSelector,
    clearRolesCard,
    createRoleRequest,
    errorSelector,
    getAllActionsRequest, 
    getAllBacklightsRequest,
    getCompanyPermissionsRequest,
    getRoleCardRequest,
    progressSelector,
    roleCardSelector,
} from '../../ducks/roles';
import { Button } from 'semantic-ui-react';
import RoleTabs from './roleTabs';
import {
    canEditSelector,
    canUseDocumentsSelector,
    canUseHistorySelector,
    clearDictionaryCard,
    columnsCardSelector,
    getCardConfigRequest,
    getDictionaryCardDefaultValueRequest
} from '../../ducks/dictionaryView';

const RoleCard = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { 
        match, 
        history, 
        location, 
        openModal
    } = props;
    const { params = {} } = match;
    const { name = 'roles', id } = params;
    const { state } = location;
    const { columns: propsColumns, copyFromId } = (state || {});

    let [form, setForm] = useState({});

    const columns = useSelector(state =>
        propsColumns ? propsColumns : columnsCardSelector(state, name, id),
    );
    const loading = useSelector(state => progressSelector(state));
    const role = useSelector(state => roleCardSelector(state)) || {};
    const error = useSelector(state => errorSelector(state)) || {};
    const canEdit = useSelector(state => canEditSelector(state, name));
    const useDocuments = useSelector(state => canUseDocumentsSelector(state, name));
    const useHistory = useSelector(state => canUseHistorySelector(state, name));
    const allPermissions = useSelector(state => allPermissionsSelector(state)) || [];
    const allActions = useSelector(state => allActionsSelector(state)) || [];
    const allBacklights = useSelector(state => allBacklightsSelector(state)) || [];

    useEffect(() => {

        id && dispatch(getCardConfigRequest({ id, name }));
        id
            ? dispatch(getRoleCardRequest(id))
            : copyFromId
                ? dispatch(getRoleCardRequest(copyFromId))
                : dispatch(getDictionaryCardDefaultValueRequest(name));

        dispatch(getAllBacklightsRequest());

        return () => {
            dispatch(clearRolesCard());
            dispatch(clearDictionaryCard());
        };
    }, []);

    useEffect(() => {
        if (form && form.companyId) {
            dispatch(getAllActionsRequest({ companyId: form.companyId.value }));
        }
    }, [form]);

    useEffect(
        () => {
            if (copyFromId) {
                role.id = null;
            }

            if(role.id)
            {
                dispatch(getCompanyPermissionsRequest({companyId: role.companyId?.value}));
            }
            
            setForm(form => ({
                ...form,
                ...role,
                permissions: role.permissions ? role.permissions.map(item => item.code) : [],
                roleBacklights: role.roleBacklights ? role.roleBacklights.map(item => item.value) : [],
                actions: role.actions ? role.actions.map(item => item.value) : [],
            }));
        },
        [role],
    );

    const title = useMemo(
        () => (id ? t('edit_role', { name: role.name }) : `${t('create_role_title')}`),
        [id, role],
    );

    const handleClose = () => {
        history.push({
            pathname: location.state.pathname,
            state: { ...location.state },
        });
    };

    const handleChange = useCallback((e, {name, value}) => {
        setForm(form => ({
            ...form,
            [name]: value,
        }));
    }, [form]);

    const onOpenModal = () => { };

    const handlePermissions = (e, { value }) => {
        const { permissions } = form;

        const selectedPermissions = new Set(permissions);

        selectedPermissions[selectedPermissions.has(value) ? 'delete' : 'add'](value);

        if (value === 1 && !selectedPermissions.has(value)) {
            selectedPermissions.delete(2);
            selectedPermissions.delete(4);
            selectedPermissions.delete(5);
            selectedPermissions.delete(6);
        }

        if (value === 7 && !selectedPermissions.has(value)) {
            selectedPermissions.delete(10);
            selectedPermissions.delete(11);
            selectedPermissions.delete(12);
        }

        handleChange(null, { name: 'permissions', value: Array.from(selectedPermissions) });
    };

    const handleActions = (e, {value}) => {
        const {actions} = form;

        const selectedActions = new Set(actions);

        selectedActions[selectedActions.has(value) ? 'delete' : 'add'](value);

        handleChange(null, {name: 'actions', value: Array.from(selectedActions)});
    };

    const handleBacklights = (e, {value}) => {
        const {roleBacklights} = form;

        const selectedBacklights = new Set(roleBacklights);

        selectedBacklights[selectedBacklights.has(value) ? 'delete' : 'add'](value);

        handleChange(null, {name: 'roleBacklights', value: Array.from(selectedBacklights)});
    };

    const mapData = () => {
        return {
            ...form,
            permissions: form.permissions.map(item => ({
                code: item,
            })),
            roleBacklights: allBacklights.filter(item => form.roleBacklights.includes(item.value)),
            actions: [...orderActions, ...shippingActions, ...dictionaryActions].filter(item => form.actions.includes(item.value)),
        };
    };

    const handleSave = () => {
        dispatch(createRoleRequest({ params: mapData(), callbackFunc: handleClose }));
    };

    const getActionsHeader = () => {
        return (
            <div></div>
        );
    };

    const getActionsFooter = useCallback(
        () => {
            return (
                <>
                    <Button color="grey" onClick={handleClose}>
                        {t('CancelButton')}
                    </Button>
                    <Button color="blue" onClick={handleSave}>
                        {t('SaveButton')}
                    </Button>
                </>
            );
        },
        [form],
    );

    const getContent = useCallback(
        () => {
            const params = {
                t,
                props,
                form,
                columns,
                name,
                id,
                error,
                title,
                canEdit,
                useDocuments,
                useHistory,
                permissions,
                allPermissions,
                actions,
                orderActions,
                shippingActions,
                dictionaryActions,
                roleBacklights,
                allBacklights,
                handlePermissions,
                handleActions,
                handleBacklights,
                onClose: handleClose,
                onChangeForm: handleChange,
                actionsFooter: getActionsFooter,
                actionsHeader: getActionsHeader,
                openModal: openModal,
                onOpenModal: onOpenModal,
                loading
            };
    
            const getContent = () => { return <RoleTabs />; };
    
            return React.cloneElement(getContent(), params);
        },
        [form, error, columns, useDocuments, useHistory],
    );

    const permissions = useMemo(() => form.permissions || [], [form]);
    const actions = useMemo(() => form.actions || [], [form]);
    const roleBacklights = useMemo(() => form.roleBacklights || [], [form]);
    const orderActions = useMemo(() => allActions.orderActions || [], [allActions]);
    const shippingActions = useMemo(() => allActions.shippingActions || [], [allActions]);
    const dictionaryActions = useMemo(() => allActions.dictionaryActions || [], [allActions]);

    return (
        <React.Fragment>
            {getContent()}
        </React.Fragment>
    );
};

export default RoleCard;
