import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Confirm } from 'semantic-ui-react';
import CardLayout from '../../components/CardLayout';
import Content from '../dictionaries/customDictionary/cardContent';

import {
    allPermissionsSelector,
    getAllPermissionsRequest,
    progressSelector,
} from '../../ducks/roles';

import {
    cardSelector,
    cardProgressSelector,
    getCardConfigRequest,
    getCardRequest,
    getDictionaryCardDefaultValueRequest,
    clearDictionaryCard,
    saveDictionaryCardRequest,
    columnsCardSelector,
    errorSelector,
    canEditSelector,
} from '../../ducks/dictionaryView';


const CompanyCard = (props) => {

    const { t } = useTranslation();
    const { match, history, location } = props;
    const { params = {} } = match;
    const { id } = params;
    const name = 'companies';
    const { state } = location;
    const { columns: propsColumns } = state;

    let [form, setForm] = useState({});
    let [notChangeForm, setNotChangeForm] = useState(true);
    let [confirmation, setConfirmation] = useState({ open: false });

    const error = useSelector(state => errorSelector(state));
    const loading = useSelector(state => cardProgressSelector(state));
    const progress = useSelector(state => progressSelector(state));
    const card = useSelector(state => cardSelector(state));
    const canEdit = useSelector(state => canEditSelector(state, name));
    const allPermissions = useSelector(state => allPermissionsSelector(state)) || [];

    const columns = useSelector(state =>
        propsColumns ? propsColumns : columnsCardSelector(state, name, id),
    );

    const title = useMemo(
        () => (id ? `${t(name)}: ${t('edit_record')}` : `${t(name)}: ${t('new_record')}`),
        [name, id],
    );

    const permissions = useMemo(() => form.permissions || [], [form]);

    const dispatch = useDispatch();

    useEffect(() => {
        id && dispatch(getCardConfigRequest({ id, name }));
        id
            ? dispatch(getCardRequest({ id, name }))
            : dispatch(getDictionaryCardDefaultValueRequest(name));

        dispatch(getAllPermissionsRequest());

        return () => {
            dispatch(clearDictionaryCard());
        };
    }, []);

    useEffect(() => {
        setForm(form => ({
            ...card,
            ...form,
            permissions: card.permissions ? card.permissions.map(item => item.code) : [],
        }));
    }, [card]);

    const handleChange = useCallback((e, { name, value }) => {
        if (notChangeForm) {
            setNotChangeForm(false);
        }

        setForm(form => ({
            ...form,
            [name]: value,
        }));
    }, [form, notChangeForm]);

    const handleSave = () => {
        dispatch(
            saveDictionaryCardRequest({
                params: mapData(),
                name,
                callbackSuccess: () => {
                    onClose();
                },
                isConfirmed: false,
            }),
        );
    };

    const handlePermissions = (e, { value }) => {
        const { permissions } = form;

        const selectedPermissions = new Set(permissions);

        selectedPermissions[selectedPermissions.has(value) ? 'delete' : 'add'](value);

        if (value === 1 && !selectedPermissions.has(value)) {
            selectedPermissions.delete(2);
            selectedPermissions.delete(4);
            selectedPermissions.delete(5);
            selectedPermissions.delete(6);
        }

        if (value === 7 && !selectedPermissions.has(value)) {
            selectedPermissions.delete(10);
            selectedPermissions.delete(11);
            selectedPermissions.delete(12);
        }

        handleChange(null, { name: 'permissions', value: Array.from(selectedPermissions) });
    };

    const handleClose = () => {
        if (notChangeForm) {
            onClose();
        } else {
            setConfirmation({
                open: true,
                content: t('confirm_close_dictionary'),
                onCancel: confirmClose,
                onConfirm: onClose,
            });
        }
    };

    const confirmClose = () => {
        setConfirmation({ open: false });
    };

    const onClose = () => {      
        history.replace({
            pathname: location.state.pathname,
            state: {
                ...location.state,
                pathname: location.state.gridLocation
                    ? location.state.gridLocation
                    : location.state.pathname,
            },
        });
    };

    const mapData = () => {
        return {
            ...form,
            permissions: form.permissions.map(item => ({
                code: item,
            })),
        };
    };

    const getContent = useCallback(
        () => {
            return [
                {
                    menuItem: 'general',
                    render: () => (
                        <Content
                            columns={columns}
                            error={error}
                            form={form}
                            canEdit={canEdit}
                            handleChange={handleChange}
                        />
                    ),
                }, {
                    menuItem: 'permissions',
                    render: () =>
                        <Form className="role-form">
                            {
                                allPermissions.map(permission => (
                                    <Form.Field key={permission.code}>
                                        <Form.Checkbox
                                            label={t(permission.name)}
                                            value={permission.code}
                                            checked={
                                                permissions && permissions.includes(permission.code)
                                            }
                                            disabled={
                                                (permissions &&
                                                    ([2, 4, 5, 6].includes(permission.code) &&
                                                        !permissions.includes(1))) ||
                                                ([10, 11, 12].includes(permission.code) &&
                                                    !permissions.includes(7))
                                            }
                                            onChange={handlePermissions}
                                        />
                                    </Form.Field>
                                ))
                            }
                        </Form>
                }
            ];
        },
        [form, error],
    );


    const getActionsFooter = useCallback(
        () => {
            return (
                <>
                    <Button color="grey" onClick={handleClose}>
                        {t('CancelButton')}
                    </Button>
                    <Button color="blue"
                        onClick={handleSave}
                        disabled={notChangeForm || progress}
                        loading={progress}
                    >
                        {t('SaveButton')}
                    </Button>
                </>
            );
        },
        [form, notChangeForm, progress],
    );


    return (
        <>
        <CardLayout
            title={title}
            actionsFooter={getActionsFooter}
            content={getContent}
            onClose={handleClose}
            loading={loading}
        />
                    <Confirm
                dimmer="blurring"
                open={confirmation.open}
                onCancel={confirmation.onCancel}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('Yes')}
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
        </>
    );
}

export default CompanyCard;
