import React, { useCallback, useState, useEffect } from 'react';
import { Table } from 'semantic-ui-react';
import ScenarioFieldCell from './scenario_field_cell';
import ScenarioSettingCell from './scenario_setting_cell';
import _, { uniq } from 'lodash';

const ScenarioTableBody = ({
    statusList,
    changeSettings,
    isExt,
    editProgress,
    t,
    toggleFieldFlags,
    column
}) => {
    let [hidden, setHidden] = useState(column.isHidden);
    let [required, setRequired] = useState(column.isRequired);
    let [unique, setUnique] = useState(column.isUnique);


    useEffect(
        () => {
            setHidden(column.isHidden);
            setRequired(column.isRequired);
            setUnique(column.isUnique);
        },
        [column.isHidden, column.isRequired, column.isUnique],
    );

    useEffect(
        () => {
            if (hidden !== column.isHidden) {
                console.debug('hidden', hidden);
                toggleFieldFlags(column.fieldName, 'hidden', hidden, isExt);
            }
        },
        [hidden],
    );

    useEffect(
        () => {
            if (required !== column.isRequired) {
                toggleFieldFlags(column.fieldName, 'required', required, isExt);
            }
        },
        [required],
    );

    useEffect(
        () => {
            if (unique !== column.isUnique) {
                toggleFieldFlags(column.fieldName, 'unique', unique, isExt);
            }
        },
        [unique],
    );

    

    const handleOnChange = useCallback(
        (e, { value, fieldName, status }) => {
            changeSettings(fieldName, value, status, isExt);
        },
        [isExt],
    );

    return (
        <Table.Row key={column.scenario}>
            <Table.Cell className="table-fields-setting_name field-settings-table_fixed">
                <ScenarioFieldCell
                    field={column.scenario}
                    fieldName={column.displayName}
                    isDisabled={column.isCalculated || hidden}
                    isExt={isExt}
                    t={t}
                    changeSettings={changeSettings}
                />
            </Table.Cell>
            {statusList.map(status => (
                <Table.Cell key={`${status.value}_${column.scenario}`}>
                    <ScenarioSettingCell
                        value={column.states && column.states[status.value]}
                        isDisabled={column.isCalculated || hidden}
                        status={status.value}
                        fieldName={column.scenario}
                        onChange={handleOnChange}
                        t={t}
                    />
                </Table.Cell>
            ))}
        </Table.Row>
    );
};

export default React.memo(ScenarioTableBody, (prevProps, currentProps) => {
    return _.isEqual(prevProps.column, currentProps.column)
});
