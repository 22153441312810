import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import { Confirm } from 'semantic-ui-react';
import TableInfo from '../../../components/TableInfo';
import {
    canCopySelector,
    canCreateByFormSelector,
    canDeleteSelector,
    canEditSelector,
    canExportToExcelSelector,
    canImportFromExcelSelector,
    clearDictionaryInfo,
    columnsGridSelector,
    deleteDictionaryEntryRequest,
    displayNameSelector,
    exportProgressSelector,
    getListRequest,
    importFromExcelRequest,
    importProgressSelector,
    listSelector,
    progressSelector,
    totalCountSelector,
} from '../../../ducks/dictionaryView';
import {DICTIONARY_ARTICLES_CARD_LINK, DICTIONARY_ARTICLES_NEW_LINK} from '../../../router/links';
import {
    actionsCardSelector,
    actionsSelector,
    allIdsSelector,
    clearActions,
    getActionsRequest,
    getAllIdsRequest,
    invokeActionRequest
} from "../../../ducks/gridActions";


class List extends Component {
    state = {
        confirmation: {
            open: false
        }
    };

    componentWillUnmount() {
        this.props.clear();
    }

    closeConfirmation = () => {
        this.setState({
            confirmation: {
                open: false,
            }
        });
    };

    showConfirmation = (content, onConfirm, onCancel) => {
        this.setState({
            confirmation: {
                open: true,
                content,
                onConfirm,
                onCancel,
            }
        });
    };

    handleImportFromExcel = (form, callbackSuccess) => {
        const { importFromExcel, match } = this.props;
        const { params = {} } = match;
        const { name = 'articles' } = params;

        const callbackConfirmation = (message) => {
            this.showConfirmation(
                message,
                () => {
                    this.closeConfirmation();

                    importFromExcel({
                        form,
                        name,
                        callbackSuccess,
                        isConfirmed: true
                    });
                },
                this.closeConfirmation
            )
        };

        importFromExcel({
            form,
            name,
            callbackSuccess,
            isConfirmed: false,
            callbackConfirmation
        });
    };

    handleDeleteEntries = (form, callbackSuccess) => {
        const { t, deleteEntries, match } = this.props;
        const { params = {} } = match;
        const { name = 'articles' } = params;

        this.showConfirmation(
            t('confirm_delete_entries').replace(':DICT', t(name)),
            () => {
                this.closeConfirmation();
                deleteEntries({
                    ...form, 
                    callbackSuccess
                });
            },
            this.closeConfirmation,
        );
    };

    mapActions = (item, t, invokeAction, name) => ({
        ...item,
        name: `${t(item.displayName || item.name)}`,
        action: (rows, clearSelectedRows) => {
            this.showConfirmation(
                `${t('Are you sure to complete')} "${t(item.displayName || item.name)}" ${
                    rows.length > 1 ? `${t('for')} ` + rows.length : ''
                    }?`,
                () => {
                    this.closeConfirmation();
                    invokeAction({
                        ids: rows,
                        name,
                        actionName: item.name,
                        callbackSuccess: () => {
                            clearSelectedRows();
                        },
                    });
                },
            );
        },
    });

    getGroupActions = () => {
        const { t, actions, invokeAction, match } = this.props;
        const { params = {} } = match;
        const { name = 'articles' } = params;
        const actionsFromGrid = actions.filter(item => item.allowedFromGrid);
        console.log('a', actionsFromGrid)

        return actionsFromGrid.map(item => this.mapActions(item, t, invokeAction, name));
    };


    render() {
        const {
            match = {},
            columns,
            loadList,
            progress,
            totalCount,
            list,
            displayName,
            isCreateBtn,
            isCopyBtn,
            isImportBtn,
            isExportBtn,
            isDeleteBtn,
            canEdit,
            importLoader,
            exportLoader,
            clear,
            t,
            getActions,
            invokeAction,
            getAllIds,
            clearActions,
            allIds
        } = this.props;
        const { params = {} } = match;
        const { name = 'articles' } = params;

        return (
            <>
                <TableInfo
                    key={name}
                    headerRow={columns}
                    name={name}
                    className={
                        columns.length >= 10
                            ? 'container'
                            : 'wider ui container container-margin-top-bottom'
                    }
                    loadList={loadList}
                    loading={progress}
                    totalCount={totalCount}
                    title={displayName}
                    list={list}
                    clear={clear}
                    storageSortItem={`${name}Sort`}
                    storageFilterItem={`${name}Filters`}
                    isCopyBtn={isCopyBtn}
                    isDeleteBtn={isDeleteBtn}
                    isImportBtn={isImportBtn}
                    isExportBtn={isExportBtn}
                    importFromExcel={this.handleImportFromExcel}
                    importLoader={importLoader}
                    exportLoader={exportLoader}
                    newLink={isCreateBtn ? DICTIONARY_ARTICLES_NEW_LINK : null}
                    cardLink={DICTIONARY_ARTICLES_CARD_LINK}
                    getActions={getActions}
                    deleteEntries={this.handleDeleteEntries}
                    getAllIds={getAllIds}
                    invokeAction={invokeAction}
                    clearActions={clearActions}
                    allIds={allIds}
                    groupActions={this.getGroupActions}
                    canEdit={canEdit}
                />
                <Confirm
                    dimmer="blurring"
                    open={this.state.confirmation.open}
                    onCancel={this.state.confirmation.onCancel || this.closeConfirmation}
                    cancelButton={t('cancelConfirm')}
                    confirmButton={t('Yes')}
                    onConfirm={this.state.confirmation.onConfirm}
                    content={this.state.confirmation.content}
                />
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { match = {} } = ownProps;
    const { params = {} } = match;
    const { name = 'articles' } = params;

    return {
        columns: columnsGridSelector(state, name),
        progress: progressSelector(state),
        totalCount: totalCountSelector(state),
        displayName: displayNameSelector(state, name),
        list: listSelector(state),
        isCopyBtn: canCopySelector(state, name),
        isDeleteBtn: canDeleteSelector(state, name),
        isCreateBtn: canCreateByFormSelector(state, name),
        isImportBtn: canImportFromExcelSelector(state, name),
        isExportBtn: canExportToExcelSelector(state, name),
        canEdit: canEditSelector(state, name),
        importLoader: importProgressSelector(state),
        exportLoader: exportProgressSelector(state),
        allIds: allIdsSelector(state),
        actions: actionsCardSelector(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadList: params => {
            dispatch(getListRequest(params));
        },
        importFromExcel: params => {
            dispatch(importFromExcelRequest(params));
        },
        clear: () => {
            dispatch(clearDictionaryInfo());
        },
        getActions: params => {
            dispatch(getActionsRequest(params));
        },
        invokeAction: params => {
            dispatch(invokeActionRequest(params));
        },
        deleteEntries: params => {
            dispatch(deleteDictionaryEntryRequest(params));
        },
        getAllIds: params => {
            dispatch(getAllIdsRequest(params));
        },
        clearActions: params => {
            dispatch(clearActions(params));
        },
    };
};

export default withTranslation()(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(List),
    ),
);
